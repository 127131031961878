var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t("settings")))]),_c('p',[_vm._v(" "+_vm._s(_vm.$t("edit_information"))+" ")]),_c('validation-observer',{ref:"companyRegisterValidation",attrs:{"name":"RegisterCompany"}},[(_vm.errors.length > 0)?_c('b-row',{staticClass:"col-md-12 pr-0 mr-0"},[_c('div',{staticClass:"alert alert-danger col-md-12 pr-0"},[_c('div',{staticClass:"p-2"},_vm._l((_vm.errors),function(err,index){return _c('div',{key:index},[_c('b-avatar',{attrs:{"variant":"light-danger","size":"45"}},[_c('feather-icon',{attrs:{"size":"21","icon":"AlertTriangleIcon"}})],1),_vm._v(" "+_vm._s(err)+" ")],1)}),0)])]):_vm._e(),_c('b-form',{on:{"submit":_vm.onSubmit,"reset":_vm.onReset}},[_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"domicile-country-group","label":_vm.$t('domicile_country') + ':',"label-for":"domicile-country"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"domicile-country","options":_vm.$store.state.app.language == 'en'
                        ? _vm.countries
                        : _vm.countries_de,"state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.country_id),callback:function ($$v) {_vm.country_id=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"country_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1),_c('b-form-group',{attrs:{"id":"company-name-group","label":_vm.$t('company_name') + ':',"label-for":"company-name"}},[_c('b-input-group',{scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.companies[_vm.company_name] != null)?_c('b-button',{on:{"click":function($event){return _vm.getCompanyDataFromZefix()}}},[_c('feather-icon',{attrs:{"icon":"DownloadIcon"}}),_vm._v(" Get Data ")],1):_vm._e()]},proxy:true}])},[_c('b-form-input',{attrs:{"autocomplete":"off","id":"company-name","required":"","list":"companies-list","disabled":""},on:{"keyup":function($event){return _vm.searchForCompaniesName()}},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_name"}})],1),_c('div',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("company_uid"))+": \""+_vm._s(_vm.company_uid)+"\" ")]),_c('datalist',{attrs:{"id":"companies-list"}},_vm._l((_vm.companies),function(company,index){return _c('option',{key:index},[_vm._v(" "+_vm._s(index)+" ")])}),0)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[(_vm.showLoader)?_c('img',{attrs:{"src":"/new-loader.svg"}}):_vm._e()])],1)],1),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-name-field-group","label":_vm.$t('company_name') + ':',"label-for":"company-name-field"}},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"HomeIcon"}})],1),_c('b-form-input',{attrs:{"id":"company-name-field","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"id":"company-address-group","label":_vm.$t('company_address') + ':',"label-for":"company-address","prepend":"@"}},[_c('validation-provider',{attrs:{"name":"Company Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address","state":errors.length > 0 ? false : null,"required":"","disabled":_vm.disableInputs},model:{value:(_vm.company_address),callback:function ($$v) {_vm.company_address=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"2","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"id":"company-address-nr-group","label":_vm.$t('company_address_nr') + ':',"label-for":"company-address-nr"}},[_c('validation-provider',{attrs:{"name":"Company Address Nr","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-address-nr","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_address_nr),callback:function ($$v) {_vm.company_address_nr=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_address_nr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-uid-group","label":_vm.$t('company_uid') + '(CHE-397.517.219 > CHE397517219):',"label-for":"company-uid"}},[_c('validation-provider',{attrs:{"name":"Company UID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-uid","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_uid),callback:function ($$v) {_vm.company_uid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_uid"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"2","md":"2","lg":"2"}},[_c('b-form-group',{attrs:{"id":"company-zip-group","label":_vm.$t('zip') + ':',"label-for":"company-zip"}},[_c('validation-provider',{attrs:{"name":"Company Zip","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-zip","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_zip),callback:function ($$v) {_vm.company_zip=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"4","md":"4","lg":"4"}},[_c('b-form-group',{attrs:{"id":"company-city-group","label":_vm.$t('city') + ':',"label-for":"company-city"}},[_c('validation-provider',{attrs:{"name":"Company City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-city","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_city),callback:function ($$v) {_vm.company_city=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-legal-form-group","label":_vm.$t('legal_form') + ':',"label-for":"company-legal-form"}},[_c('validation-provider',{attrs:{"name":"Company Legal Form","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-select',{attrs:{"id":"company-legal-form","options":[
                      { value: 0, text: _vm.$t('sole_proprietorship') },
                      { value: 1, text: _vm.$t('general_partnership') },
                      { value: 2, text: _vm.$t('limited_partnership') },
                      { value: 3, text: _vm.$t('corporation') },
                      { value: 4, text: _vm.$t('limited_liability_company') },
                      { value: 5, text: _vm.$t('cooperative') },
                      { value: 6, text: _vm.$t('cantonal_public_company')},
                      { value: 7, text: _vm.$t('association')}
                     ],"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.company_legal_form),callback:function ($$v) {_vm.company_legal_form=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_legal_form"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"language_of_correspondence'","label":_vm.$t('language_of_correspondence') + ':',"label-for":"language_of_correspondence'"}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-form-select',{attrs:{"options":[
                      { text: _vm.$t('german'), value: 'de' },
                      { text: _vm.$t('english'), value: 'en' } ],"id":"language_of_correspondence","disabled":_vm.disableInputs,"required":""},model:{value:(_vm.language),callback:function ($$v) {_vm.language=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"language"}})],1)],1)],1)],1)],1),_c('h5',[_vm._v(_vm._s(_vm.$t("financial_data")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-established-at-group","label":_vm.$t('established_since') + ':',"label-for":"company-established-at-field"}},[_c('validation-provider',{attrs:{"name":"Established since","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-datepicker',{attrs:{"no-flip":"","id":"company-established-at-field","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs,"required":""},model:{value:(_vm.company_established_at),callback:function ($$v) {_vm.company_established_at=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"company_established_at"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-number-of-employees-group","label":_vm.$t('number_of_employees') + ':',"label-for":"company-number-of-employees"}},[_c('validation-provider',{attrs:{"name":"Number of employees","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"number","id":"company-number-of-employees","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.number_of_employees),callback:function ($$v) {_vm.number_of_employees=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"number_of_employees"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"company-annual-turnover-group","label":_vm.$t('annual_turnover') + ':',"label-for":"company-annual-turnover-field"}},[_c('validation-provider',{attrs:{"name":"Annual turnover","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-annual-turnover-field","required":"","type":"number","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.annual_turnover),callback:function ($$v) {_vm.annual_turnover=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"annual_turnover"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('open_debt_enforcement_against_you') + '?'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio-group',{attrs:{"disabled":_vm.disableInputs,"id":"company-debt-enforcement","options":_vm.debt_enforcement_values,"aria-describedby":ariaDescribedby,"name":"debt-enforcement"},model:{value:(_vm.debt_enforcement),callback:function ($$v) {_vm.debt_enforcement=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"debt_enforcement"}})]}}])})],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}})],1),_c('h5',[_vm._v(_vm._s(_vm.$t("main_contact_person")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-gender","label":_vm.$t('salutation') + ':',"label-for":"main-contact-gender"}},[_c('validation-provider',{attrs:{"name":"main-contact-gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-select',{attrs:{"id":"main-contact-gender","disabled":_vm.disableInputs,"options":[
                        {
                          text: _vm.$t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: _vm.$t('mr'), value: '0' },
                        { text: _vm.$t('mrs'), value: '1' } ],"required":"","state":errors.length > 0 ? false : null},model:{value:(_vm.main_contact_gender),callback:function ($$v) {_vm.main_contact_gender=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_gender"}})],1)]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-email-group","label":_vm.$t('company_email_address') + ':',"label-for":"main-contact-email"}},[_c('validation-provider',{attrs:{"name":"Number of Debtors","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-email","required":"","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.main_contact_email),callback:function ($$v) {_vm.main_contact_email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-first-name-group","label":_vm.$t('first_name') + ':',"label-for":"main-contact-first-name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-first-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_first_name),callback:function ($$v) {_vm.main_contact_first_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_first_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-tel-group","label":_vm.$t('mobile') + ':',"label-for":"main-contact-tel"}},[_c('validation-provider',{ref:"phoneValidator",attrs:{"name":"phoneValidation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',[_c('b-input-group-prepend',{staticClass:"phoneCountries",attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"options":_vm.phoneList},model:{value:(_vm.phoneContries),callback:function ($$v) {_vm.phoneContries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phoneContries"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":"main-contact-tel","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},on:{"input":function($event){return _vm.checkPhoneFormat()}},model:{value:(_vm.main_contact_tel),callback:function ($$v) {_vm.main_contact_tel=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_tel"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))]),(_vm.phoneValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneValidation))]):_vm._e()]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-last-name-group","label":_vm.$t('last_name') + ':',"label-for":"main-contact-last-name"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-last-name","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_last_name),callback:function ($$v) {_vm.main_contact_last_name=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"main-contact-position-group","label":_vm.$t('position_in_the_company') + ':',"label-for":"main-contact-position"}},[_c('validation-provider',{attrs:{"name":"Position in the Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":"main-contact-position","required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.main_contact_position),callback:function ($$v) {_vm.main_contact_position=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"main_contact_position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":"signing-authority-type-group","label":_vm.$t('salutation') + ':',"label-for":"signing-authority-type"}},[_c('validation-provider',{attrs:{"name":"signing-authority-type-group","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserPlusIcon"}})],1),_c('b-form-select',{attrs:{"disabled":_vm.disableInputs,"id":"signing-authority-type","options":[
                        {
                          text: _vm.$t('select_one'),
                          value: null,
                          disabled: true,
                        },
                        { text: _vm.$t('no_signatory'), value: '2' },
                        { text: _vm.$t('sole_signatory'), value: '0' },
                        { text: _vm.$t('collectively_by_two'), value: '1' } ],"required":"","state":errors.length > 0 ? false : null},on:{"change":_vm.hideSignersPart},model:{value:(_vm.signing_authority_type),callback:function ($$v) {_vm.signing_authority_type=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"signing_authority_type"}})],1)]}}])})],1)],1)],1)],1),(_vm.signators.length > 0)?_c('h5',[_vm._v(_vm._s(_vm.$t("other_signers")))]):_vm._e(),_vm._l((_vm.signators),function(signator,index){return _c('b-card',{key:index,staticStyle:{"background":"#fefefe"}},[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-gender-group',"label":_vm.$t('salutation') + ':',"label-for":index + 'signator-gender'}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-select',{attrs:{"id":index + 'signator-gender',"options":[
                      {
                        text: _vm.$t('select_one'),
                        value: null,
                        disabled: true,
                      },
                      { text: _vm.$t('mr'), value: '0' },
                      { text: _vm.$t('mrs'), value: '1' } ],"required":"","disabled":_vm.disableInputs},model:{value:(signator.gender),callback:function ($$v) {_vm.$set(signator, "gender", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.gender"}})],1)],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-email-group',"label":_vm.$t('company_email_address') + ':',"label-for":index + 'signator-email'}},[_c('validation-provider',{attrs:{"name":index + ' Email',"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"MailIcon"}})],1),_c('b-form-input',{attrs:{"id":index + 'signator-email',"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(signator.email),callback:function ($$v) {_vm.$set(signator, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-first-name-group',"label":_vm.$t('first_name') + ':',"label-for":index + 'signator-first-name'}},[_c('validation-provider',{attrs:{"name":index + ' First Name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":index + 'signator-first-name',"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(signator.name),callback:function ($$v) {_vm.$set(signator, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-tel-group',"label":_vm.$t('mobile') + ':',"label-for":index + 'signator-tel'}},[_c('b-input-group',{},[_c('b-input-group-prepend',{staticClass:"phoneCountries",attrs:{"id":"phoneCountries"}},[_c('b-form-select',{attrs:{"options":_vm.phoneList},model:{value:(signator.iso_code),callback:function ($$v) {_vm.$set(signator, "iso_code", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.iso_code"}})],1),_c('b-form-input',{staticClass:"form-control",attrs:{"id":index + 'signator-tel',"disabled":_vm.disableInputs},on:{"input":function($event){return _vm.checkPhoneFormatSignator(
                        signator.tel,
                        signator.iso_code
                      )}},model:{value:(signator.tel),callback:function ($$v) {_vm.$set(signator, "tel", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.tel"}})],1),(_vm.phoneSignatorValidation)?_c('span',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.phoneSignatorValidation))]):_vm._e()],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-last-name-group',"label":_vm.$t('last_name') + ':',"label-for":index + 'signator-last-name'}},[_c('validation-provider',{attrs:{"name":index + ' Last Name',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserIcon"}})],1),_c('b-form-input',{attrs:{"id":index + 'signator-last-name',"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(signator.last_name),callback:function ($$v) {_vm.$set(signator, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.last_name"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-position-group',"label":_vm.$t('position_in_the_company') + ':',"label-for":index + 'signator-position'}},[_c('validation-provider',{attrs:{"name":index + ' Position',"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"BriefcaseIcon"}})],1),_c('b-form-input',{attrs:{"id":index + 'signator-position',"required":"","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(signator.position),callback:function ($$v) {_vm.$set(signator, "position", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.position"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,true)})],1)],1)],1),_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-form-group',{attrs:{"id":index + 'signator-signing-authority-type-group',"label":_vm.$t('signing_authority_type') + ':',"label-for":index + 'signator-signing-authority-type'}},[_c('b-input-group',{staticClass:"input-group-merge"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"UserPlusIcon"}})],1),_c('b-form-select',{attrs:{"id":index + 'signator-signing-authority-type',"options":[
                      {
                        text: _vm.$t('select_one'),
                        value: null,
                        disabled: true,
                      },
                      { text: _vm.$t('no_signatory'), value: '2' },
                      { text: _vm.$t('sole_signatory'), value: '0' },
                      { text: _vm.$t('collectively_by_two'), value: '1' } ],"required":"","disabled":_vm.disableInputs},model:{value:(signator.signer.signing_authority_type),callback:function ($$v) {_vm.$set(signator.signer, "signing_authority_type", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"signator.signer.signing_authority_type"}})],1)],1)],1),_c('b-col',{staticClass:"text-right pt-2",attrs:{"cols":"12","xl":"6","md":"6","lg":"6"}},[_c('b-button',{attrs:{"disabled":_vm.disableInputs,"variant":"outline-danger"},on:{"click":function($event){return _vm.deleteSignator(index, signator)}}},[_c('feather-icon',{attrs:{"icon":"DeleteIcon"}}),_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")],1)],1)],1)],1)}),(_vm.signators.length < 5 && _vm.hideSigners)?_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"disabled":_vm.disableInputs,"variant":"outline-primary"},on:{"click":function($event){return _vm.addNewSigner()}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t("add_signatory"))+" ")],1)],1)],1):_vm._e(),_c('h5',[_vm._v(_vm._s(_vm.$t("fee_policy")))]),(!_vm.preregistered)?_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms-and-conditions","name":"terms-and-conditions","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.data_privacy),callback:function ($$v) {_vm.data_privacy=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"data_privacy"}},[_vm._v(" "+_vm._s(_vm.$t("agree_with_policy_seller"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}],null,false,3503861844)}),_c('div',{staticClass:"p-1 mt-1",staticStyle:{"background":"#efefef","padding":"10px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("agree_with_policy_seller_subtitle"))+" ")])])],1)],1)],1)],1):_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms-and-conditions","name":"terms-and-conditions","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.preregistered),callback:function ($$v) {_vm.preregistered=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"preregistered"}},[_vm._v(" "+_vm._s(_vm.$t("preregistered_company_payment"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])}),_c('div',{staticClass:"p-1 mt-1",staticStyle:{"background":"#efefef","padding":"10px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("preregistered_company_text"))+" ")])])],1)],1)],1)],1),_c('h5',[_vm._v(_vm._s(_vm.$t("legal_notice")))]),_c('b-card',[_c('b-row',{attrs:{"m-0":"","p-0":""}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"terms-and-conditions","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"terms-and-conditions","name":"terms-and-conditions","state":errors.length > 0 ? false : null,"disabled":_vm.disableInputs},model:{value:(_vm.terms_and_conditions),callback:function ($$v) {_vm.terms_and_conditions=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"terms_and_conditions"}},[_vm._v(" "+_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_before_link"))+" "),_c('span',{staticClass:"web"},[_c('a',{on:{"click":function($event){return _vm.toTerms()}}},[_vm._v(_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_link")))])]),_c('span',{staticClass:"mobile"},[_c('a',{on:{"click":function($event){return _vm.toTerms()}}},[_vm._v(_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_link")))])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t(errors[0])))])]}}])}),_c('div',{staticClass:"mt-1",staticStyle:{"background":"#efefef","padding":"10px"}},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_subtitle"))+" ")]),_c('p',{staticClass:"col-md-12"},[_vm._v(" "+_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_1"))+" ")]),_c('p',{staticClass:"col-md-12"},[_vm._v(" "+_vm._s(_vm.$t("agree_with_terms_&_conditions_seller_2"))+" ")])])],1)],1)],1)],1),_c('b-row',{staticClass:"col-12 text-end justify-content-end",attrs:{"m-0":"","p-0":"","align":"end"}},[_c('div',{staticClass:"mr-2"},[_c('b-link',{staticClass:"btn btn-outline-primary",attrs:{"to":"/"}},[_c('FeatherIcon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t("back_to_dashboard"))+" ")],1)],1),(!_vm.disableInputs)?_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.saveCompanyInfo()}}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.$t("edit_information"))+" ")],1)],1):_vm._e()])],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }